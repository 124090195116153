import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import copy from "clipboard-copy";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import ImageLoader from '../../components/ImageLoader';
import FacebookShareButton from '../../components/FacebookShareButton';

import './index.css';

import config from "../../utils/global";
import { useGlobalState } from "../../store/index";
import { success, info, error } from "../../utils/toast";
import { get, post } from "../../utils/http";
import { trackPurchaseEvent } from '../../utils/gtag';

function NftResult() {
    const navigate = useNavigate();
    const [globalIsLogin] = useGlobalState(
        "globalIsLogin"
    );
    const [loaderState, setLoaderState] = useState(true);
    const [nftsList, setNftList] = useState([]);
    const [order, setOrder] = useState({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('tips');
    const [shareParams, setShareParams] = useState({});
    const [needUserDetail, setNeedUserDetail] = useState(false);
    const [email, setEmail] = useState("");

    const searchParams = new URLSearchParams(window.location.search);
    const payOrderID = searchParams.get("orderId");
    const payState = searchParams.get("payState");
    const nftID = searchParams.get("nftIDs");

    const carouselSettings = {
        showThumbs: false, // 禁用底部的缩略图
        infiniteLoop: false, // 循环播放
        autoPlay: false, // 自动播放
        interval: 3000, // 自动播放间隔时间（毫秒）
        showArrows: true, // 显示左右箭头
        showStatus: false, // 隐藏状态指示器
        showIndicators: false, // 禁用底部小圆点
        swipeable: false, // 允许通过滑动切换图片
        emulateTouch: false, // 启用触摸模拟
        dynamicHeight: false, // 禁用动态高度，确保图片横向展示
    };
    const elementType = {
        1: "Cơ bản",
        2: "Lửa"
    };
    const nftLevs = {
        0: "Fire",
        1: "Bronze",
        2: "Silver",
        3: "Gold",
        4: "Platinum",
        5: "Diamond"
    };

    useEffect(() => {
        async function fetchData() {
            await loadLocalToken();
        }
        if (globalIsLogin === true) {
            fetchData();
        }
    }, [globalIsLogin]);

    const loadLocalToken = async () => {
        if (payState && payOrderID) {
            //購買
            if (payState === "success") {
                // 支付成功
                await fetchOrder();
            } else if (payState === "partialsuccess") {
                setTimeout(async () => {
                    // 支付成功等待
                    await fetchOrder();
                }, 30000);
            } else {
                error(`Parameter error.`);
            }
        } else if (nftID) {
            // 合成
            await fetchCraftResult();
        } else {
            error(`Parameter error.`);
        }
    };

    const fetchOrder = async () => {
        const rep = await get("yeah1Payment/getOrderByTableID", {
            orderTableID: payOrderID,
        });
        if (rep.code === 0) {
            if (rep.data.orderInfo.order_type === 0) {
                setOrder(rep.data);
                setNftList(rep.data.nftList);
                setLoaderState(false);
                if (rep.data.needUserDetail === true) {
                    setNeedUserDetail(rep.data.needUserDetail);
                    setModalType('form');
                    await fetchMyWallet();
                    setModalOpen(true);
                }
                const items = [
                    {
                        item_id: rep.data.orderInfo.id,
                        item_name: nftLevs[rep.data.nftList[0].nft_lv],
                        item_brand: 'NFT',
                        price: ((rep.data.orderInfo.total_amount) / (rep.data.buyAmount)),
                        quantity: rep.data.buyAmount,
                    }
                ];
                pointPurchase(items);
            } else {
                error(`Parameter error.`);
            }
        } else {
            error(rep.msg);
        }
    };

    const pointPurchase = (items) => {
        // console.log('items=>', items);
        try {
            const totalValue = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
            const tax = null;
            trackPurchaseEvent(items.item_id, totalValue, 'VND', items, tax);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchCraftResult = async () => {
        const rep = await get("yeah1Apps/getCraftResult", {
            nftTableIDs: nftID,
        });
        if (rep.code === 0) {
            setOrder(rep.data);
            setNftList(rep.data.nftList);
            setLoaderState(false);
        } else {
            error(rep.msg);
        }
    };

    const fetchMyWallet = async () => {
        const rep = await get("yeah1Apps/getUserVoteInfo", {});
        if (rep.code === 0) {
            setEmail(rep.data.userInfo.email || '');
        } else {
            error(rep.msg);
        }
    };

    // 获取选项信息
    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    // 投票
    const handleVote = () => {
        let callbackUrl = window.localStorage.getItem("vote_url");
        if (callbackUrl) {
            window.location.href = `${callbackUrl}`;
        } else {
            window.location.href = `${config.api.voteURL}`;
        }
    };

    //合成
    const handleSynthesis = () => {
        navigate("/synthesizeNft");
    };

    // 轉送
    const handleSend = () => {
        let _nftId = nftsList && nftsList[currentSlide].id;
        navigate(`/nftSend?nftId=${_nftId}`);
    };

    // 購買
    const handleBuy = () => {
        navigate("/buyNft");
    };

    // 提上鏈
    const handleNftWithdraw = () => {
        let _nftId = nftsList && nftsList[currentSlide].id;
        navigate(`/nftWithdraw?nftId=${_nftId}`);
    };

    // 查看我的NFT
    const handleMyNfts = () => {
        navigate("/myNftList");
    };

    // 每週最大投票數渲染
    const renderNftlvMaxVote = (_nft_lv) => {
        switch (_nft_lv) {
            case 2:
                return 99;
            case 3:
                return 199;
            case 4:
                return 399;
            case 5:
                return 999;
            default:
                return 99;
        }
    }

    const openShareModal = (_nft_name, _nft_share, _nft_img) => {
        let share = {
            nftName: _nft_name,
            nftDesc: _nft_share,
            nftImg: _nft_img
        };
        setShareParams(share);
        copy(_nft_share);
        setNeedUserDetail(false);
        setModalType('share');
        setModalOpen(true);
    };

    const closeModal = () => {
        if (needUserDetail === true && modalType !== 'tips') {
            setModalType('tips');
        } else {
            setModalOpen(false);
        }
    };

    const backModal = () => {
        setModalType('form');
        setModalOpen(true);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (email.length > 1) {
            if (!emailRegex.test(email)) {
                info('Vui lòng nhập email hợp lệ!');
                return;
            }
        }

        fetchUserDetail();
    };

    const fetchUserDetail = async () => {
        const rep = await post("user/updateUserDetail", {
            phone: '',
            email: email,
            address: ''
        });
        if (rep.code === 0) {
            success('Cập nhật thông tin cá nhân thành công!');
            await fetchMyWallet();
            setModalOpen(false);
        } else {
            error(rep.msg);
        }
    }

    const typeModal = () => {
        switch (modalType) {
            case 'share':
                return (
                    <div className='facebook-share-diglog'>
                        <div className='share-title'>Hãy chia sẻ lên dòng thời gian Facebook</div>
                        <div className='share-quote'>
                            {shareParams.nftDesc}
                        </div>
                        <div className='share-button'>
                            <FacebookShareButton
                                quote={shareParams.nftDesc}
                                imageUrl={shareParams.nftImg}
                            />
                        </div>
                    </div>
                );
            case 'form':
                return (
                    <div className="contact-form">
                        <div className="form-txt">
                            <div className="txt-cover">
                                <img src="/images/tran.png" alt="nft1" />
                            </div>
                            <div className="txt-title">Chúc mừng bạn!</div>
                            <div className="txt-subtitle">
                                Bạn đã nhận được một quà tặng số kỷ niệm đặc biệt từ BB Trần.
                            </div>
                            <div className="txt-smtitle">
                                (Có cơ hội nhận được khi mua các gói vàng, bạch kim hoặc kim cương. Xác suất nhận được cao hơn đối với các gói bạch kim và cao cấp hơn.)
                            </div>
                            <div className="txt-subtitle">
                                Trong tương lai, chúng tôi sẽ tổ chức các hoạt động kỷ niệm đặc biệt dành cho những người may mắn nhận được quà tặng này. Vui lòng để lại Email của bạn để nhận thông báo trong tương lai
                            </div>
                        </div>
                        <div className="form-row">
                            <label htmlFor="email">Email</label>
                            <input
                                className="input"
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                required
                                placeholder="Vui lòng nhập email hợp lệ"
                            />
                        </div>
                        <br />
                        <center>
                            <Button
                                size={"small-default"}
                                onClick={handleSubmit}
                            >
                                Xác nhận
                            </Button>
                        </center>
                    </div>
                );
            default:
                return (
                    <div className='facebook-share-diglog'>
                        <div className='share-title'>Hãy chia sẻ lên dòng thời gian Facebook</div>
                        <div className='share-quote'>
                            Nếu bạn muốn gửi email sau, bạn có thể điền thông tin tại "Thông tin web3 của tôi" (<a href={`${config.api.siteURL}/myWeb3Info`} target='_blank'>{`${config.api.siteURL}/myWeb3Info`}</a>).
                        </div>
                        <div className='share-button flex-button'>
                            <Button css={"yes"} size={"small"} onClick={backModal}>
                                Quay lại
                            </Button>
                            <Button css={"no"} size={"small"} onClick={closeModal}>
                                Dóng
                            </Button>
                        </div>
                    </div>
                );
        }
    }

    return (
        <div className="app">
            <Seo
                title={`Yvote - Mua gói`}
                description={`Yvote - Funfusion Mua gói`}
                keywords={`Yvote,Funfusion,NFT,Web3,Mua gói`}
                image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
                url={window.location.href}
            />
            <TopNavigationBar back={false} check={true} />
            <div className="nft-result">
                {order && (
                    <div className="vote-info">
                        <div className="icon"></div>
                        <div className="box-title">
                            Thanh toán thành công
                        </div>
                        <div className="box">
                            <div className="rows">
                                Chi tiết quà tặng
                            </div>
                            <div className="rows">
                                <ul>
                                    {!nftID && (<li>{order.attach_vote_count}×{order.buyAmount} lượt bình chọn trên nền tảng YVote</li>)}
                                    <li>{order.buyAmount} quà tặng sản phẩm số phiên bản cấp {(order.nftList && order.nftList[0].nft_lv)}</li>
                                    <li>{order.nftList && renderNftlvMaxVote(order.nftList[0].nft_lv)} lượt bình chọn trong tuần</li>
                                </ul>
                            </div>
                            <div className="opts">
                                <Button size={"small-default"} onClick={handleVote}>
                                    Bình chọn
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="result-info" >
                    <div className="title">Bạn nhận được quà tặng số</div>
                    <div className="nft-info">
                        <Carousel {...carouselSettings} onChange={handleSlideChange}>
                            {nftsList.map((nfts, index) => {
                                return (
                                    <div key={`nft_i_${index}`}>
                                        <div className="nft-pic" key={`nft_pic_${index}`}>
                                            <ImageLoader
                                                key={`pic_img_${index}`}
                                                css="nft-img"
                                                src={nfts.show_image}
                                                alt={nfts.show_name}
                                            />
                                            <div className="nft-name">{nfts.show_name}</div>
                                        </div>
                                        <div className="nft-num">{index + 1}/{nftsList.length || 0}</div>
                                        <div className='facebook-share-button' onClick={() => openShareModal(nfts.show_name, `Qua bao ngày vượt Chông Gai nay đã săn được quà tặng số ${nfts.show_name}. Sưu tầm đủ 33 quà tặng số Anh Tài cùng mình nhé:${config.api.siteURL}/buyNft?inviteld=${nfts.user_id} #AnhTraiVượtNgànChôngGai #YeaH1 #FunFusion #${nfts.show_name}`, nfts.show_image)}>
                                            Chia sẻ lên Facebook
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                        <div className="facebook-share-group" >
                            <a href="https://www.facebook.com/profile.php?id=61562766936516" target="_blank">Quan tâm đến trang chủ của cộng đồng</a>
                        </div>
                    </div>
                </div>
                <ul className="nft-result-menus">
                    <li onClick={handleSynthesis}>
                        <div className="icon-synthesizer"></div>
                        <div className="menu-text">Tổng hợp</div>
                        <div className="icon-arrow-right"></div>
                    </li>
                    <div className="bubble" onClick={handleSynthesis}>
                        <div className="bubble-desc">
                            Việc tổng hợp gói sẽ mang lại cho bạn cơ hội nhận được quà tặng số phiên bản giới hạn của đội (cấp độ tổng hợp càng cao, khả năng nhận được quà càng lớn). Hãy thử ngay nhé!
                        </div>
                        <div className="bubble-imgs">
                            <img src="/images/nft-1.png" alt="nft1" />
                            <img src="/images/nft-2.png" alt="nft2" />
                            <img src="/images/nft-3.png" alt="nft3" />
                            <img src="/images/nft-4.png" alt="nft4" />
                            <img src="/images/nft-5.png" alt="nft5" />
                            <img src="/images/nft-6.png" alt="nft6" />
                            <img src="/images/nft-7.png" alt="nft7" />
                            <img src="/images/nft-8.png" alt="nft8" />
                        </div>
                    </div>
                    {/* <li onClick={handleSend}>
                        <div className="icon-send"></div>
                        <div className="menu-text">Gửi đến bạn bè</div>
                        <div className="icon-arrow-right"></div>
                    </li> */}
                    <li onClick={handleNftWithdraw}>
                        <div className="icon-withdraw"></div>
                        <div className="menu-text">Rút về ví của tôi</div>
                        <div className="icon-arrow-right"></div>
                    </li>
                    <li onClick={handleBuy}>
                        <div className="icon-buy"></div>
                        <div className="menu-text">Thêm gói</div>
                        <div className="icon-arrow-right"></div>
                    </li>
                </ul>
                <div className="info-group">
                    <Button size={"small-default"} onClick={handleMyNfts}>
                        Quay lại quà tặng số của tôi
                    </Button>
                </div>
            </div>
            <Footer />
            <Loader isOpen={loaderState} />
            <Modal styleCss={'default'} isOpen={modalOpen} onClose={closeModal}>
                {typeModal()}
            </Modal>
        </div >
    );
}

export default NftResult;
