import React, { useState, useEffect } from "react";
import copy from "clipboard-copy";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";

import "./index.css";

import { success, error } from "../../utils/toast";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";
import { replaceWalletAddress, formatWalletAddress } from "../../utils/tools";

const MyWeb3Info = () => {
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [yeah1ID, setYeah1ID] = useState("");
  const [walletAddr, setWalletAddr] = useState("");

  useEffect(() => {
    async function fetchData() {
      await fetchMyWallet();
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchMyWallet = async () => {
    const rep = await get("yeah1Apps/getUserVoteInfo", {});
    if (rep.code === 0) {
      setUserInfo(rep.data.userInfo);
      setYeah1ID(rep.data.userInfo.id);
      setLoaderState(false);
      if (rep.data.userInfo.wallet_address) {
        setWalletAddr(replaceWalletAddress(rep.data.userInfo.wallet_address));
      }
    } else {
      error(rep.msg);
    }
  };

  const handleCopy = (_copyText) => {
    copy(_copyText)
      .then(() => {
        success(`Văn bản đã được sao chép vào khay nhớ tạm: ${_copyText}`);
      })
      .catch((error) => {
        error("Sao chép văn bản vào khay nhớ tạm thất bại. Vui lòng thử lại.");
      });
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Thông tin web3 của tôi`}
        description={`Yvote - Funfusion Thông tin web3 của tôi`}
        keywords={`Yvote,Funfusion,NFT,Web3,Thông tin web3 của tôi`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={true} check={true} />
      <div className="my-web3-info">
        <div className="item-text">FunID của tôi</div>
        <div className="item">
          <div className="value">{yeah1ID}</div>
          <div className="copy-right" onClick={() => handleCopy(yeah1ID)}>
          </div>
        </div>
        <div className="item-text">Địa chỉ ví của tôi</div>
        <div className="item">
          <div className="value">
            {walletAddr}
          </div>
          <div className="copy-right" onClick={() => handleCopy(walletAddr)}>
          </div>
        </div>
        <div className="item-text">Khóa riêng</div>
        <div className="item">
          <div className="value">
            {formatWalletAddress(userInfo.private_key) || ""}
          </div>
          <div className="copy-right" onClick={() => handleCopy(userInfo.private_key || "")}>
          </div>
        </div>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default MyWeb3Info;
