import React, { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'js-cookie';

import "./index.css";

import config from "../../utils/global";
import { error } from "../../utils/toast";
import { noTokenPost } from "../../utils/http";
import { useGlobalState } from "../../store/index";

const TopNavigationBar = ({ back, check, onBack }) => {
  const navigate = useNavigate();
  const [globalIsLogin, setGlobalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const match = useMatch('*');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems] = useState([
    { id: 1, title: 'Bình chọn', link: config.api.voteURL },
    { id: 2, title: 'Mua gói', link: '/buyNft' },
    { id: 3, title: 'Gói tổng hợp', link: '/synthesizeNft' },
    { id: 4, title: 'Quà tặng số của tôi', link: '/myNftList' },
    { id: 5, title: 'Thông tin web3 của tôi', link: '/myWeb3Info' },
    // { id: 7, title: 'My Rights', link: '/myRights' },
    { id: 8, title: 'Lịch sử giao dịch', link: '/history' },
    { id: 9, title: 'Hướng dẫn', link: '/faq' }
  ]);
  const [topnavClass, setTopnavClass] = useState("topnav-menus-dialog");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (check === true) {
      // console.log('check=>', check);
      let localToken = window.localStorage.getItem("token") || '';
      let cookies_token = Cookies.get('access_token') || '';
      // console.log('localToken=>', localToken);
      // console.log('cookiesToken=>', cookies_token);
      if (cookies_token === '' || cookies_token === 'undefined') {
        window.location.href = `${config.api.loginURL}${window.location.href}`;
        return;
      } else {
        if (localToken === '') {
          await fetchOlalaLogin(cookies_token);
        } else {
          setGlobalIsLogin(true);
        }
      }
    }
  };

  useEffect(() => {
    const body = document.body;
    if (isMenuOpen === true) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  const fetchOlalaLogin = async (_olalaToken) => {
    const rep = await noTokenPost("Login/yeah1OlalaLogin", {
      olala_token: _olalaToken,
    });
    if (rep.code === 0) {
      window.localStorage.setItem("token", rep.data);
      setGlobalIsLogin(true);
    }
    else if (rep.code === 2012) {
      // todo gogo
    }
    else {
      error(rep.msg);
    }
  };

  const toggleMenu = () => {
    if (isMenuOpen === true) {
      setTopnavClass("topnav-menus-dialog");
    } else {
      setTopnavClass("topnav-menus-dialog slide-out");
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const handleUrl = (_id, _url, _title) => {
    if (_id === 1) {
      window.location.href = _url;
      return;
    }
    navigate(_url);
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <header className="pc-navbar">
        <div className="navbar">
          <div className="navbar-logo">
            <a href="/">
              <img src={`${process.env.PUBLIC_URL}/images/one-production-logo.png`} alt="Funfusion" />
            </a>
          </div>
          <nav className="navbar-menu">
            <ul>
              {menuItems.map((item, index) => {
                if (index < 5) {
                  return (
                    <li
                      key={`pc_menus_more_${index}`}
                      className={item.link === match.pathname ? 'achk' : 'li'}
                      onClick={() => handleUrl(item.id, item.link)}
                    >
                      {item.title}
                    </li>
                  );
                }
                return null;
              })}
              <li key={`menus_more`} className="more">
                <span className="icon-more">...</span>
                <ul className="submenu">
                  <li key={`pc_menus_more_8`} onClick={() => handleUrl(8, '/history')}>Lịch sử giao dịch</li>
                  <li key={`pc_menus_more_9`} onClick={() => handleUrl(9, '/faq')}>Hướng dẫn</li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <header className={'topnav'}>
        <div className="operating">
          {back ? <div className="icon-back" onClick={handleBack}></div> : null}
          <div className="logo">
            <img src={`${process.env.PUBLIC_URL}/images/one-production-logo.png`} alt="Funfusion" />
          </div>
        </div>
        <div className="navigation" onClick={toggleMenu}>
          <div className="menus-icon"></div>
        </div>
      </header>
      <div className={topnavClass}>
        <div className="menus-head">
          <div className="menus-head-logo">
            <img src={`${process.env.PUBLIC_URL}/images/one-production-logo.png`} alt="Funfusion" />
          </div>
          <div className="menus-head-close">
            <div className="close-icon" onClick={toggleMenu}></div>
          </div>
        </div>
        <div className="menus-list">
          {menuItems.map((item, index) => {
            return (
              <div key={`menus_${item.id}_${index}`} className={item.link === match.pathname ? 'current' : 'li'} onClick={() => handleUrl(item.id, item.link)}>{item.title}</div>
            );
          })}
        </div>
      </div>
      <ToastContainer className="custom-toast-container" />
    </>
  );
};

export default TopNavigationBar;
