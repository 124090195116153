import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Bnb } from 'react-web3-icons';

import TopNavigationBar from "../../components/TopNavigationBar"; // 导入顶部导航栏组件
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import ImageLoader from '../../components/ImageLoader';

import "./index.css";

import config from "../../utils/global";
import { error } from "../../utils/toast";
import { get } from "../../utils/http";
import { formatWalletAddress, isMobile, replaceWalletAddress } from "../../utils/tools";
import { useGlobalState } from "../../store/index";

const NftWithdrawResult = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [nft, setNft] = useState({});
  const [loaderState, setLoaderState] = useState(true);
  const [payState, setPayState] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const payResult = searchParams.get("payState");
  const payOrderID = searchParams.get("orderId");
  const elementType = {
    1: "Cơ bản",
    2: "Lửa"
  };

  useEffect(() => {
    async function fetchData() {
      await loadLocalToken();
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const loadLocalToken = async () => {
    if (payResult && payOrderID) {
      if (payResult === "success") {
        await fetchOrder();
        setPayState(true);
      } else if (payResult === "partialsuccess") {
        setTimeout(() => {
          fetchOrder();
          setPayState(true);
        }, 30000);
      } else if (payResult === "failed") {
        await fetchOrder();
        setPayState(false);
      } else {
        error(`Parameter error.`);
      }
    } else {
      error(`Parameter error.`);
    }
  };

  const fetchOrder = async () => {
    const rep = await get("yeah1Payment/getOrderByTableID", {
      orderTableID: payOrderID,
    });
    if (rep.code === 0) {
      setNft(rep.data);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const handleNfts = () => {
    navigate("/myNftList");
  };

  const handleScan = (_contract_address) => {
    if (isMobile()) {
      window.location.href = `${config.network.exploreUrl}/${replaceWalletAddress(_contract_address)}/60`;
    } else {
      window.open(`${config.network.exploreUrl}/${replaceWalletAddress(_contract_address)}/60`);
    }
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Rút`}
        description={`Yvote - Funfusion Rút`}
        keywords={`Yvote,Funfusion,NFT,Web3,Rút`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={true} />
      <div className="nft-withdraw-result">
        <div
          className={payState === false ? "paystate failed" : "paystate success"}
        >
          <div className="icon"></div>
          <div className="msg">
            {payState === true ? <div className="success">Rút về ví thành công.</div> : <div className="failed">Rút về ví thất bại.</div>}
          </div>
        </div>
        <div className="withdraw-result-box">
          <div className="pic">
            <ImageLoader
              src={nft.show_image}
              alt={nft.show_name}
            />
          </div>
          <div className="li">{nft.show_name}</div>
          <div className="li">Cấp độ {nft.nft_lv || 1}</div>
          <div className="li">{elementType[nft.nft_type || 1]}</div>
          {nft.trans_hash && (
            <div className="li">
              TokenID:<span> {formatWalletAddress(nft.contract_address)}</span>
            </div>
          )}
          <div className="line"></div>
          <div className="li">Rút về</div>
          {nft.contract_address && (
            <div className="li">
              <Bnb size={20} />
              <span className="chain" onClick={() => handleScan(nft.contract_address)}>Xem trên BNB CHAIN</span>
            </div>
          )}
        </div>
        <div className="cmd">
          <Button size={"small-default"} onClick={handleNfts}>
            Quay lại quà tặng số của tôi
          </Button>
        </div>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default NftWithdrawResult;
