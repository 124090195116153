import Cookies from 'js-cookie';

import config from "../utils/global";
import { getParamsHash } from "../utils/tools";
import { getFingerprint } from "../utils/fingerprint";

export async function fetchData(url, options) {
    // console.log('fetchData=>url=>', url, 'options=>', options);
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.code === 2003 || data.code === 2004 || data.code === 10001 || data.code === 10002 || data.code === 10003 || data.code === 10004) {
            let cookies_token = Cookies.get('access_token') || '';
            console.log('token=>失效了=>', cookies_token);
            
            if (cookies_token === '' || cookies_token === 'undefined') {
                window.location.href = `${config.api.loginURL}${window.location.href}`;
                return;
            } else {
                window.localStorage.clear();
                const rep = await noTokenPost("Login/yeah1OlalaLogin", {
                    olala_token: cookies_token,
                });
                if (rep.code === 0) {
                    window.localStorage.setItem("token", rep.data);
                    window.location.href = window.location.href;
                }
                else if (rep.code === 2012) {
                    window.location.href = `${config.api.loginURL}${window.location.href}`;
                }
                else {

                }
            }
            return data;
        }
        else if (data.code === 100503) {
            window.location.href = '/maintenance';
        }
        else {
            return data;
        }
    } catch (error) {
        // console.error('Error:', error);
        throw error;
    }
}

export async function get(endpoint, params) {
    let url = `${config.api.baseURL}/${endpoint}`;
    if (params) {
        let queryParams = new URLSearchParams(params).toString();
        url = `${url}?${queryParams}`;
    }
    let token = window.localStorage.getItem("token");
    let cookies_token = Cookies.get('access_token');
    let fingerprint = await getFingerprint();
    let options = {
        method: 'GET',
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-access-token': token ? token : '',
            'y-access-token': cookies_token ? cookies_token : '',
            'x-sign': `${getParamsHash(params)}`,
            // 'x-fingerprint': fingerprint,
        },
    };
    return await fetchData(url, options);
};

export async function post(endpoint, params) {
    let url = `${config.api.baseURL}/${endpoint}`;
    let token = window.localStorage.getItem("token");
    let cookies_token = Cookies.get('access_token');
    let queryParams = new URLSearchParams(params).toString();
    let fingerprint = await getFingerprint();

    const options = {
        method: 'POST',
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-access-token': token ? token : '',
            'y-access-token': cookies_token ? cookies_token : '',
            'x-sign': `${getParamsHash(params)}`,
            // 'x-fingerprint': fingerprint,
        },
        body: queryParams
    };
    return await fetchData(url, options);
}

export async function postJson(endpoint, params) {
    let url = `${config.api.baseURL}/${endpoint}`;
    let token = window.localStorage.getItem("token");
    let cookies_token = Cookies.get('access_token');
    let fingerprint = await getFingerprint();

    const options = {
        method: 'POST',
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token ? token : '',
            'y-access-token': cookies_token ? cookies_token : '',
            'x-sign': `${getParamsHash(params)}`,
            // 'x-fingerprint': fingerprint,
        },
        body: JSON.stringify(params)
    };
    return await fetchData(url, options);
}

export async function noTokenGet(endpoint, params) {
    let url = `${config.api.baseURL}/${endpoint}`;
    let fingerprint = await getFingerprint();

    if (params) {
        const queryParams = new URLSearchParams(params).toString();
        url = `${url}?${queryParams}`;
    }
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-sign': `${getParamsHash(params)}`,
            // 'x-fingerprint': fingerprint,
        },
    };
    return await fetchData(url, options);
};

export async function noTokenPost(endpoint, params) {
    let url = `${config.api.baseURL}/${endpoint}`;
    let fingerprint = await getFingerprint();

    const options = {
        method: 'POST',
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/json',
            'x-sign': `${getParamsHash(params)}`,
            // 'x-fingerprint': fingerprint,
        },
        body: JSON.stringify(params)
    };
    return await fetchData(url, options);
}